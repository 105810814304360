import React, { Component } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { buildBlocks } from "../services/builder";
import { CoreFields } from "./fragment"; // eslint-disable-line
import { CoreBlockFragment } from "../components/blocks/CoreBlock"; // eslint-disable-line
import Breadcrumb from "../components/Breadcrumb";

import { CgbNewsPagePostsBlock } from "../components/blocks/CgbNewsPagePostsBlock";

/**
 * This page should query the posts based on the context provided and pass the results to the post-list block
 * No post-list block? Show an error
 */
class ListNewsPosts extends Component {
  render() {
    // console.log(this.props);

    return (
      <Layout
        meta={{
          metaDescription:
            "Cronex’s latest updates! Discover breaking news, industry insights, and more from our trusted brand.",
          metaTitle: "Latest News | Cronex",
          ogDescription: "",
          ogTitle: "",
          twitterCardType: "",
          twitterDescription: "",
          twitterTitle: "",
          canonical: "/latest-news/",
        }}
        path={"/latest-news/"}
        title={"Latest News"}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="news-post-list"
          current={{
            title: "Latest News",
            slug: "latest-news",
            uri: "/latest-news",
          }}
        />
        <CgbNewsPagePostsBlock
          attributes={{
            className: "",
          }}
          pageContext={this.props.pageContext}
          parentAttributes={{ posts: this.props.data.allWpNewsPost.edges }}
        />
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allWpNewsPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          slug
          uri
          title
          excerpt
          date(formatString: "DD MMMM YYYY")
          featuredImage {
            node {
              mediaItemUrl
              altText
              localFile {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default ListNewsPosts;
