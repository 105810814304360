import React from "react";
import PropTypes from "prop-types";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import Pagination from "@material-ui/lab/Pagination";
import themeOptions from "../../../../.brew-cache/theme.json";
import { replaceMasks } from "../../../services/masks";
import ReactHtmlParser from "react-html-parser";
import styles from "./styles";
import { CoreHeadingBlock } from "../CoreHeadingBlock";
import { CoreButtonBlock } from "../CoreButtonBlock";
import Img from "gatsby-image";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbNewsPagePostsBlock = (props) => {
  const classes = useStyles();
  const { pageNumber, totalPages } = props.pageContext;
  let { posts } = props.parentAttributes;

  // console.log("pageContext", props.pageContext);

  const poststoShow = posts.slice(
    pageNumber * Number(10) - Number(10),
    pageNumber * Number(10)
  );

  const data = useStaticQuery(graphql`
    query DefaultFeaturedNewsImage {
      wp {
        themeOptions {
          ThemeOptions {
            defaultImages {
              postFeaturedImage {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  if (typeof posts === "undefined") {
    // set some sample data
    posts = [
      {
        node: {
          title: "Example Post",
          date: "2020-01-01 00:00:00",
          excerpt:
            "<p>This is just a test post used for previewing the listing page. Real posts will appear here when this page is published.</p>",
          uri: "/",
          featuredImage: {
            node: {
              mediaItemUrl: "",
              altText: "",
            },
          },
        },
      },
    ];
  }

  const defaultImage = (
    <Img
      fluid={
        data.wp.themeOptions.ThemeOptions.defaultImages.postFeaturedImage
          .localFile.childImageSharp.fluid
      }
    />
  );

  return (
    <div
      className={`cgbNewsPagePostsBlock ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.blogPagePosts} ${classes.blogPagePostsTheme}`}
    >
      <div className="page-title">
        <CoreHeadingBlock
          attributes={{
            align: "",
            anchor: "",
            className: "",
            content: "Latest News",
            level: 1,
            textColor: "",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
      </div>
      <div className="blog-list">
        {poststoShow.map((post) => {
          let image = null;
          // This is a preview image
          if (
            post.node.featuredImage === null ||
            post.node.featuredImage.node === null ||
            post.node.featuredImage.node.localFile === null
          ) {
            image = defaultImage;
          } else {
            image = (
              <Img
                fluid={
                  post.node.featuredImage.node.localFile.childImageSharp.fluid
                }
              />
            );
          }

          return (
            <div className="list-item" key={post.node.id}>
              {image}
              <div className="con-wrap">
                <p className="date">{post.node.date}</p>
                <h2>{post.node.title}</h2>
                <p>
                  {ReactHtmlParser(
                    replaceMasks(post.node.excerpt.replace(/(<([^>]+)>)/gi, ""))
                  )}
                </p>

                <CoreButtonBlock
                  attributes={{
                    align: "",
                    backgroundColor: "tertiary",
                    borderRadius: 0,
                    className: "view-all",
                    gradient: "",
                    linkTarget: "",
                    placeholder: "",
                    rel: "",
                    text: "Read More",
                    textColor: "primary",
                    title: "",
                    url: `/latest-news/${post.node.slug}`,
                    __typename: "WpCoreButtonBlockAttributes",
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          page={pageNumber}
          onChange={(e, value) => {
            value === 1
              ? navigate(`${props.pageContext.id}`)
              : navigate(`${props.pageContext.id}page/${value}`);
          }}
        />
      )}
    </div>
  );
};

CgbNewsPagePostsBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};
